import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { CurrencyCode } from '~common/generated/storefront-graphql'
import { Price } from '../product/Price'
import DateField from '~ui/DateField'
import clsx from 'clsx'
import { EditType } from './types'

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ''
const shorten = (interval: string, intervalCount: number) => {
  switch (interval) {
    case 'Month':
    case 'month':
      if (intervalCount > 1) {
        return `${intervalCount} mths`
      }
      return 'mth'
    case 'Week':
    case 'week':
      if (intervalCount > 1) {
        return `${intervalCount} wks`
      }
      return 'wk'
    case 'Year':
    case 'year':
      if (intervalCount > 1) {
        return `${intervalCount} yrs`
      }
      return 'yr'
    default:
      return interval
  }
}
export function PaymentScheduleDetails({
  programPrice,
  duration,
  productInterval,
  interval,
  intervalCount,
  downpayment = 0,
  discountedDownpayment = 0,
  amount,
  finalAmount,
  recurringRegistrationDiscount = 0,
  recurringDiscount,
  discountPerInterval = 0,
  currency,
  startDate,
  proration = 0,
  displayDetails = true,
  defaultToOpen = true,
  editable = [],
  type,
  downpaymentIsRegistration,
  onEdit,
}: {
  programPrice: number
  duration: number
  productInterval: string
  interval: string
  intervalCount: number
  downpayment: number
  discountedDownpayment: number
  amount: number
  finalAmount: number
  recurringRegistrationDiscount: number
  recurringDiscount: number
  discountPerInterval?: number
  currency: string
  startDate: string
  proration?: number
  displayDetails?: boolean
  defaultToOpen?: boolean
  editable?: EditType[]
  type: 'recurring' | 'registration'
  downpaymentIsRegistration?: boolean
  onEdit?: () => void
}) {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultToOpen)
  const handleToggle = React.useCallback(() => {
    if (onEdit) {
      onEdit()
    }
    if (displayDetails) {
      setIsOpen((open) => !open)
    }
  }, [displayDetails, onEdit])

  if (duration == undefined) {
    return null
  }

  return (
    <div
      className={clsx(
        'text-xs w-full font-medium text-gray-500 dark:text-slate-300',
      )}
    >
      {type === 'registration' ? (
        <>
          <div
            className={clsx(
              'w-full grid grid-cols-12 dark:text-slate-400 overflow-y-hidden',
              {
                'max-h-0': !isOpen,
                'max-h-96': isOpen,
              },
            )}
          >
            <p className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400">
              Program price
            </p>
            <Price
              priceWithTax={programPrice}
              currencyCode={currency as CurrencyCode}
              className={clsx(
                'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3',
                'text-right dark:text-slate-300',
              )}
            />
          </div>
          <div
            className={clsx(
              'w-full grid grid-cols-12 transition-max-height duration-300 ease-in-out overflow-y-hidden mt-0.5',
              {
                // 'w-full justify-between': proration === 0,
                'max-h-0': !isOpen,
                'max-h-96': isOpen,
              },
            )}
            data-open={isOpen}
            onClick={onEdit}
          >
            <span
              className={clsx('col-span-7 md:col-span-5 xl:col-span-4', {
                'text-gray-500 dark:text-slate-400': !downpaymentIsRegistration,
                'text-success-400':
                  downpaymentIsRegistration &&
                  (!onEdit || !editable.includes('amounts')),
                'text-primary-500 cursor-pointer':
                  downpaymentIsRegistration &&
                  !!onEdit &&
                  editable.includes('amounts'),
              })}
            >
              {downpaymentIsRegistration ? 'Registration fee' : 'Downpayment'}
            </span>
            <Price
              priceWithTax={amount}
              currencyCode={currency as CurrencyCode}
              className={clsx(
                'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                {
                  'cursor-pointer': !!onEdit && editable.includes('amounts'),
                },
              )}
            />
            {/*discount > 0 ? (
              <span>
                (
                <Price
                  priceWithTax={amount}
                  currencyCode={currency as CurrencyCode}
                />
                )
              </span>
            ) : null*/}
          </div>
          {!downpaymentIsRegistration ? (
            <>
              <div
                data-open={isOpen}
                className={clsx(
                  'transition-max-height duration-300 ease-in-out overflow-y-hidden',
                  {
                    'max-h-0': !isOpen,
                    'max-h-96': isOpen,
                  },
                )}
              >
                {recurringRegistrationDiscount > 0 ? (
                  <div className="w-full grid grid-cols-12">
                    <span
                      className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400"
                      title="Recurring discount"
                    >
                      Discount
                    </span>
                    <Price
                      priceWithTax={-recurringRegistrationDiscount}
                      currencyCode={currency as CurrencyCode}
                      className={clsx(
                        'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                        {
                          'text-primary-500 cursor-pointer':
                            !!onEdit && editable.includes('amounts'),
                        },
                      )}
                    />
                  </div>
                ) : null}
                {finalAmount > 0 ? (
                  <span className="flex">
                    <span className="dark:text-slate-400">Starting</span>
                    &nbsp;
                    <DateField initialDate={startDate} editable={false} />
                  </span>
                ) : null}
                <div className="flex">
                  <span className="dark:text-slate-400">Every</span>&nbsp;
                  {duration} {capitalize(interval)}s
                </div>
              </div>
              <div
                className={clsx('w-full grid grid-cols-12', {
                  // 'cursor-pointer': !displayDetails
                  'cursor-pointer': displayDetails,
                  // 'w-full justify-between': proration === 0,
                })}
                onClick={handleToggle}
              >
                <div className="col-span-7 md:col-span-5 xl:col-span-6 pt-0.5 flex items-center gap-0.5">
                  <span className="text-success-400">Periodic downpayment</span>
                  <ChevronRightIcon
                    aria-hidden="true"
                    className={clsx(
                      'h-3 w-3 text-success-500 dark:text-success-400',
                      {
                        hidden: !displayDetails,
                        '-rotate-90': isOpen,
                      },
                    )}
                  />
                </div>
                <Price
                  priceWithTax={finalAmount}
                  currencyCode={currency as CurrencyCode}
                  className={clsx(
                    'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right pt-0.5',
                    {
                      'border-t border-gray-600': isOpen,
                      'text-primary-500 cursor-pointer':
                        !!onEdit && editable.includes('amounts'),
                    },
                  )}
                />
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={clsx(
              'transition-max-height duration-300 ease-in-out overflow-y-hidden',
              {
                'max-h-0': !isOpen,
                'max-h-96': isOpen,
              },
            )}
          >
            <div
              className={clsx('w-full grid grid-cols-12', {
                // 'w-full justify-between': proration === 0,
              })}
              onClick={onEdit}
            >
              <span className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400">
                Sub total
              </span>
              <span className="col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right whitespace-nowrap">
                <Price
                  priceWithTax={programPrice - discountedDownpayment}
                  currencyCode={currency as CurrencyCode}
                  className={clsx('text-right', {
                    'cursor-pointer': !!onEdit && editable.includes('amounts'),
                  })}
                />
              </span>
            </div>
            <div
              className={clsx('w-full grid grid-cols-12', {
                // 'w-full justify-between': proration === 0,
              })}
              onClick={onEdit}
            >
              <span className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400">
                Subscription
              </span>
              <span className="col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right whitespace-nowrap">
                <Price
                  priceWithTax={finalAmount + recurringDiscount}
                  currencyCode={currency as CurrencyCode}
                  className={clsx('text-right', {
                    'cursor-pointer': !!onEdit && editable.includes('amounts'),
                  })}
                />
                <span className="text-success-600 dark:text-success-400">
                  /{shorten(interval, intervalCount)}
                </span>
              </span>
            </div>
            {recurringDiscount > 0 ? (
              <div className="w-full grid grid-cols-12">
                <span
                  className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400"
                  title="Recurring discount"
                >
                  Discount
                </span>
                <span className="col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right whitespace-nowrap">
                  {/* Be aware that this is the recurring discount per selected interval, not per duration interval */}
                  <Price
                    priceWithTax={-recurringDiscount}
                    currencyCode={currency as CurrencyCode}
                    className={clsx('text-right', {
                      'text-primary-500 cursor-pointer':
                        !!onEdit && editable.includes('amounts'),
                    })}
                  />
                  <span className="text-success-600 dark:text-success-400">
                    /{shorten(interval, intervalCount)}
                  </span>
                </span>
              </div>
            ) : null}
            {/*discountPerInterval > 0 ? (
              <div className="w-full grid grid-cols-12">
                <span
                  className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400"
                  title="Recurring discount"
                >
                  Downpayment discount
                </span>
                <Price
                  priceWithTax={-discountPerInterval}
                  currencyCode={currency as CurrencyCode}
                  className={clsx(
                    'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                  )}
                />
              </div>
            ) : null*/}
            {finalAmount > 0 ? (
              <span className="flex">
                <span className="dark:text-slate-400">Starting</span>&nbsp;
                <DateField initialDate={startDate} editable={false} />
              </span>
            ) : null}
            <div className="flex">
              <span className="dark:text-slate-400">Paid</span>&nbsp;
              {intervalCount > 1 ? `Every ${intervalCount} ` : ''}
              {capitalize(interval)}
              {intervalCount > 1 ? 's' : 'ly'}
            </div>
          </div>
          <div
            className={clsx('w-full grid grid-cols-12', {
              'cursor-pointer': displayDetails,
              // 'w-full justify-between': proration === 0,
            })}
            onClick={handleToggle}
          >
            <div className="col-span-7 md:col-span-5 xl:col-span-6 pt-0.5 flex items-center gap-0.5">
              <span className="text-success-400">Subscription payment</span>
              <ChevronRightIcon
                aria-hidden="true"
                className={clsx(
                  'h-3 w-3 text-success-500 dark:text-success-400',
                  {
                    hidden: !displayDetails,
                    '-rotate-90': isOpen,
                  },
                )}
              />
            </div>
            <span
              className={clsx(
                'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right whitespace-nowrap',
                {
                  'border-t border-gray-600': isOpen,
                },
              )}
            >
              <Price
                priceWithTax={finalAmount}
                currencyCode={currency as CurrencyCode}
                className={clsx('text-right', {
                  'text-primary-500 cursor-pointer':
                    !!onEdit && editable.includes('amounts'),
                })}
              />
              <span className="text-success-600 dark:text-success-400">
                /{shorten(interval, intervalCount)}
              </span>
            </span>
          </div>
        </>
      )}
    </div>
  )
}
